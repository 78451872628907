// @ts-check

import React, { useState } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useParams,
  useHistory
} from "react-router-dom";

import * as Constants from './utils/constants';

import { NotFound } from './screens/Errors/NotFound';
import { Unauthorized } from './screens/Errors/Unauthorized';
import { useEffect } from "react";
import DocumentationAPI from "./screens/DocumentationAPI";
import { ToastBar } from './toaster'
import toast from "react-hot-toast";

import {
  setApiKey,
  uploadEventLog,
  AttachmentPreview,
  HtmlPreview,
  PdfPreview,
  LocalprintDynamoPdf,
  LocalprintViewpointPdf,
  WebEditorDesign,
  MetaforceProvider
} from '@metaforcelabs/metaforce-webeditor';


import { config } from "./utils/config";
import Msal from './screens/Msal';

function App() {

  return (
    <div className="app h-screen tailwind-preflight" style={{ height: '100vh' }}>

      <Router>
        <Switch>
          <Route exact={true} path="/unauthorized" component={Unauthorized} />
          <Route exact={true} path="/not-found" component={NotFound} />
          {/* TODO REMOVE just testing*/}
          <Route exact={true} path="/msal" component={Msal} />

          <WebEditorRoute exact={true} path="/WebEditor/:startParameter" nav={Constants.menuState.edit} component={WebEditorDesign} />
          <WebEditorRoute exact={true} path="/WebEditor/Attachments/:startParameter" nav={Constants.menuState.attachment} component={WebEditorDesign} />

          <WebEditorPdfRoute exact={true} path="/WebEditor/Preview/Pdf/DxmlAndAttachments/:startParameter" component={PdfPreview} />
          <WebEditorPdfRoute exact={true} path="/WebEditor/Preview/Html/:startParameter" component={HtmlPreview} />
          <WebEditorPdfRoute exact={true} path="/WebEditor/Preview/Dynamo/Pdf/:startParameter/:jobId-:docId.pdf" component={LocalprintDynamoPdf} />
          <WebEditorPdfRoute exact={true} path="/WebEditor/Preview/Viewpoint/Pdf/:startParameter.pdf" component={LocalprintViewpointPdf} />
          <WebEditorPdfRoute exact={true} path="/WebEditor/Preview/Pdf/Attachment/:startParameter/:publishedAttachmentId" component={AttachmentPreview} />

          <Route exact={true} path="/documentation-api" component={DocumentationAPI} />
        </Switch>

      </Router>

      <ToastBar />
    </div>
  );
}

function WebEditorRoute({
  component: Component,
  nav,
  ...rest
}) {
  const location = useLocation();

  setApiKey();

  const uploadOpenDocumentEventLog = async () => {
    const params = location.pathname?.split('/');
    const startParameter = params?.pop();
    if (startParameter) {
      await uploadEventLog({
        startParameter: startParameter,
        type: Constants.auditEventLogType.openDocument,
      });
    }
  }

  useEffect(() => {
    uploadOpenDocumentEventLog();
  }, [])

  return (
    <Route {...rest} render={(props) =>
      <MetaforceProviderWrapper {...props}>
        <div
        >
          <Component nav={nav} {...props} />
        </div>
      </MetaforceProviderWrapper>
      // <MetaforceProvider 
      //   configurations={config}
      //   themeAndStyle={themeAndStyles}
      //   >

      // </MetaforceProvider>
    } />
  )
}

function WebEditorPdfRoute({
  component: Component,
  ...rest
}) {

  return (
    <Route {...rest} render={(props) =>
      <MetaforceProviderWrapper {...props}>
        <div className="tw-h-screen">
          <Component {...props} />
        </div>
      </MetaforceProviderWrapper>
      // <MetaforceProvider
      //   configurations={config}
      //   themeAndStyle={themeAndStyles}
      // >
      //   <Component  {...props} />
      // </MetaforceProvider>
    } />
  )
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function MetaforceProviderWrapper({ children }) {
  const { startParameter, jobId, docId, publishedAttachmentId } = useParams();
  const query = useQuery();
  const history = useHistory();
  const metaforceProviderConfig = { startParameter, jobId, docId, publishedAttachmentId, jwt: query.get("jwt"), ...config }
  const [error, setError] = React.useState();

  const toastHandler = (message, type) => {
    switch (type) {
      case 'error':
        toast.error(message, {
          duration: Infinity,
        });
        break;
      case 'success':
      default:
        toast.success(message);
        break;
    }
  }

  const handleOneTimeJwtUsed = () => {
    if (query.get("jwt"))
      query.delete('jwt')
    history.replace({
      search: query.toString(),
    })
  }

  return (
    <MetaforceProvider
      configurations={metaforceProviderConfig}
      toastHandler={toastHandler}
      //customErrorHandler={(e) => setError(e)}
      themeAndStyle={{
        hideActionButtonText: true,
        // hideContextMenu: false,
      }}
      onOneTimeJwtUsed={handleOneTimeJwtUsed}
    >
      {
        // error ?
        // (<div>Error loading document</div>)
        // :
        children
      }
    </MetaforceProvider>
  )
}

export default App;

import toast, { Toaster as BaseToaster } from 'react-hot-toast';
import { CheckCircleIcon } from '@heroicons/react/outline'
import { XCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'

export const ToastBar = () => {
  return (
    <BaseToaster position="top-right">
      {(t) => {
        return (
          <div
            className={`${t.visible ? 'tw-animate-enter' : 'tw-animate-leave'
              } tw-max-w-md tw-w-full tw-bg-white tw-shadow-lg tw-rounded-lg tw-pointer-events-auto tw-flex tw-ring-1 tw-ring-black tw-ring-opacity-5`}
          >
            {
              t.type === "loading" &&
              <>
                <div className="tw-flex-1 tw-w-0 tw-p-4">
                  <div className="tw-flex tw-items-start tw-items-center">
                    <div className="tw-flex-shrink-0 tw-pt-0.5">
                    </div>
                    <div className="tw-ml-3 tw-flex-1 ">
                      <p className="tw-text-sm tw-font-medium tw-text-gray-900">
                        {t.message}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            }
            {
              t.type === 'success' &&
              <>
                <div className="tw-flex-1 tw-w-0 tw-p-4">
                  <div className="tw-flex tw-items-start tw-items-center">
                    <div className="tw-flex-shrink-0 tw-pt-0.5">
                      <CheckCircleIcon className="tw-h-6 tw-w-6 tw-text-green-400" aria-hidden="true" />
                    </div>
                    <div className="tw-ml-3 tw-flex-1 ">
                      <p className="tw-text-sm tw-font-medium tw-text-gray-900">
                        {t.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tw-flex tw-border-l tw-border-gray-200">
                  <button
                    onClick={() => toast.remove(t.id)}
                    className="tw-w-full tw-border tw-border-transparent tw-rounded-none tw-rounded-r-lg tw-p-4 tw-flex tw-items-center tw-justify-center 
                    tw-text-sm tw-font-medium tw-text-indigo-600 hover:tw-text-brand-pink focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-400"
                  >
                    <span className="tw-sr-only">Close</span>
                    <XIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                  </button>
                </div>
              </>
            }
            {
              t.type === 'error' &&
              <>
                <div className="tw-flex-1 tw-w-0 tw-px-4 tw-py-2 tw-text-white" style={{ backgroundColor: '#fa7578' }}>
                  <div className="tw-flex tw-items-start tw-items-center">
                    <div className="tw-flex-shrink-0 tw-pt-0.5">
                      <XCircleIcon className="tw-h-6 tw-w-6 " aria-hidden="true" />
                    </div>
                    <div className="tw-ml-3 tw-flex-1">
                      <p className="tw-text-sm tw-font-medium">
                        {t.message}
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        toast.remove(t.id)

                        if (t.message === 'Unable to save record. Record has been modified by another user.') {
                          window.location.reload()
                        }
                      }}
                      className="tw-border tw-border-transparent tw-rounded-none tw-rounded-r-lg tw-px-3 tw-py-2 tw-flex tw-items-center tw-justify-center 
                    tw-text-sm tw-font-medium tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-400"
                      style={{ color: "black" }}
                    >
                      Clear Message
                    </button>
                  </div>
                </div>
              </>
            }
          </div>
        )
      }}
    </BaseToaster >
  )
}